import * as React from 'react'

import { DirectionsBoat } from '@mui/icons-material'
import { Grid, Stack, Typography } from '@mui/material'
import dayjs from 'dayjs'
import { StaticImage } from 'gatsby-plugin-image'

import CursiveTypography from '../../components/cursive_typography'
import { FacebookIcon, InstagramIcon, TripAdvisorIcon } from '../../components/icons'
import { ParallaxGroup, ParallaxLayer } from '../../components/parallax'

type StatContainerProps = {
  icon: React.ReactNode
  title: React.ReactNode
  subtitle: React.ReactNode
}

const StatContainer = ({
  icon,
  title,
  subtitle,
}: StatContainerProps) => (
  <Grid
    item
    xs={6}
    md={3}
    pt={3}
  >
    <Stack
      alignItems='center'
      justifyContent='center'
    >
      {icon}
      <Typography
        variant='h1'
        component='p'
        fontWeight={500}
        letterSpacing='-0.05em'
        mt={2}
        display='flex'
        alignItems='center'
        textAlign='center'
      >
        {title}
      </Typography>
      <Typography textAlign='center'>
        {subtitle}
      </Typography>
    </Stack>
  </Grid>
)

export const LandingSocials = () => (
  <ParallaxGroup sx={{ minHeight: '600px' }}>
    <ParallaxLayer depth={-1}>
      <StaticImage
        src='../../images/background-socials.jpeg'
        alt='Entrada del restaurant'
        layout='fullWidth'
        style={{
          position: 'absolute',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          filter: 'blur(4px)',
        }}
        quality={90}
      />
    </ParallaxLayer>
    <ParallaxLayer
      sx={{
        background: 'linear-gradient(to bottom, rgba(53, 53, 53, 0.85) 0%, rgba(0, 0, 0, 0.85) 100%)',
        color: 'background.default',
      }}
    >
      <Stack
        p={3}
        height='100%'
        alignItems='center'
        justifyContent='center'
      >
        <CursiveTypography
          variant='h2'
          textAlign='center'
        >
          Nuestros clientes en redes sociales
        </CursiveTypography>
        <Typography
          variant='subtitle1'
          component='p'
          textAlign='center'
          gutterBottom
        >
          #OceanPacifics
        </Typography>
        <Grid
          container
          maxWidth='md'
          columnSpacing={2}
        >
          <StatContainer
            icon={(
              <DirectionsBoat
                color='primary'
                sx={{ fontSize: '4em' }}
              />
            )}
            title={(
              <React.Fragment>
                {dayjs(new Date()).diff('1989-11-01', 'years')}
                <span style={{ fontSize: '.5em' }}>+</span>
              </React.Fragment>
            )}
            subtitle='Años de experiencia'
          />
          <StatContainer
            icon={(
              <FacebookIcon
                color='primary'
                sx={{ fontSize: '4em' }}
              />
            )}
            title={(
              <React.Fragment>
                31.000
                <span style={{ fontSize: '.5em' }}>+</span>
              </React.Fragment>
            )}
            subtitle='Fans en Facebook'
          />
          <StatContainer
            icon={(
              <TripAdvisorIcon
                color='primary'
                sx={{ fontSize: '4em' }}
              />
            )}
            title='4,5'
            subtitle='Puntuación promedio en TripAdvisor'
          />
          <StatContainer
            icon={(
              <InstagramIcon
                color='primary'
                sx={{ fontSize: '4em' }}
              />
            )}
            title={(
              <React.Fragment>
                49.000
                <span style={{ fontSize: '.5em' }}>+</span>
              </React.Fragment>
            )}
            subtitle='Seguidores en Instagram'
          />
        </Grid>
      </Stack>
    </ParallaxLayer>
  </ParallaxGroup>
)

export default LandingSocials
