import * as React from 'react'

import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import { IconButton, Link, Stack, Typography } from '@mui/material'
import { StaticImage } from 'gatsby-plugin-image'

import CursiveTypography from '../../components/cursive_typography'
import { ParallaxGroup, ParallaxLayer } from '../../components/parallax'

type VideoLinkProps = {
  href: string
  text: React.ReactNode
}

const VideoLink = ({
  href,
  text,
}: VideoLinkProps) => (
  <Link
    target='_blank'
    rel='noopener noreferrer'
    href={href}
    sx={(theme) => ({
      color: 'background.default',
      fontSize: theme.typography.subtitle1.fontSize,
      lineHeight: 1,
      textDecoration: 'underline',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: 1,
      mt: 2,
    })}
  >
    <IconButton
      aria-label='Ver vídeo'
      size='large'
      color='primary'
      sx={{
        mr: 1,
        color: 'background.default',
        bgcolor: 'action.active',
        border: 2,
        ':hover': {
          bgcolor: 'primary.light',
        },
      }}
    >
      <PlayArrowIcon />
    </IconButton>
    {text}
  </Link>
)

export const LandingHero = () => (
  <ParallaxGroup sx={{ minHeight: '560px' }}>
    <ParallaxLayer depth={-1}>
      <StaticImage
        src='../../images/background.jpeg'
        alt='Entrada del restaurant'
        layout='fullWidth'
        style={{
          position: 'absolute',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
        }}
        quality={90}
      />
    </ParallaxLayer>
    <ParallaxLayer
      sx={{
        background: 'linear-gradient(to top, rgba(53, 53, 53, 0.8) 0%, rgba(0, 0, 0, 0.8) 100%)',
        color: 'background.default',
      }}
    >
      <Stack
        p={3}
        height='100%'
        alignItems='flex-start'
        justifyContent='center'
      >
        <CursiveTypography
          variant='h1'
          gutterBottom
        >
          Destino Gastronómico y Patrimonial de Chile
        </CursiveTypography>
        <Typography
          variant='subtitle1'
          component='p'
          gutterBottom
        >
          ¿Estás listo para vivir la experiencia Ocean&nbsp;Pacific&apos;s Restaurant?
        </Typography>
        <VideoLink
          href='https://www.youtube.com/watch?v=ewN_DTaPKgk'
          text={<React.Fragment>Ver video: Recorrido virtual &ndash; Buque Insignia</React.Fragment>}
        />
        <VideoLink
          href='https://www.youtube.com/watch?v=g8Ka77ur6I8'
          text={<React.Fragment>Ver video: Recorrido virtual &ndash; Esmeralda</React.Fragment>}
        />
      </Stack>
      <IconButton
        aria-label='Desplazarse hacia abajo en la página'
        href='#restaurantes'
        sx={(theme) => ({
          color: 'background.default',
          position: 'fixed',
          left: '50%',
          transform: 'translateX(-50%)',
          bottom: theme.spacing(10),
        })}
      >
        <ArrowCircleDownIcon />
      </IconButton>
    </ParallaxLayer>
  </ParallaxGroup>
)

export default LandingHero
