import * as React from 'react'

import BookButton from '../components/book_button'
import NavBar from '../components/nav_bar'
import { Parallax } from '../components/parallax'
import SeoHeaders from '../components/seo_headers'
import SocialButtons from '../components/social_buttons'
import LandingAwards from '../sections/landing/awards'
import LandingFooter from '../sections/landing/footer'
import LandingHero from '../sections/landing/hero'
import LandingMenus from '../sections/landing/menus'
import LandingRestaurants from '../sections/landing/restaurants'
import LandingSocials from '../sections/landing/socials'

const IndexPage = () => {
  return (
    <React.Fragment>
      <SeoHeaders
        description={(
          "¿Estás listo para vivir la experiencia Ocean Pacific's Restaurant? Conoce"
          + ' nuestros restaurantes en Santiago Centro y Vitacura.'
        )}
      />
      <NavBar />
      <SocialButtons />
      <BookButton />
      <Parallax>
        <LandingHero />
        <LandingRestaurants />
        <LandingMenus />
        <LandingAwards />
        <LandingSocials />
        <LandingFooter />
      </Parallax>
    </React.Fragment>
  )
}

export default IndexPage
