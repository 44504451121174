import * as React from 'react'

import { Grid, Link, Stack } from '@mui/material'
import { StaticImage } from 'gatsby-plugin-image'

import CursiveTypography from '../../components/cursive_typography'
import { ParallaxGroup, ParallaxLayer } from '../../components/parallax'

export const LandingMenus = () => (
  <ParallaxGroup
    id='cartas'
    sx={(theme) => ({
      zIndex: 2,
      minHeight: '800px',
      [theme.breakpoints.up('sm')]: {
        minHeight: '450px',
        maxHeight: '550px',
      },
    })}
  >
    <ParallaxLayer bgcolor='background.default'>
      <Stack
        spacing={2}
        minHeight='100%'
        alignItems='center'
        justifyContent='center'
        sx={(theme) => ({
          pt: 2,
          [theme.breakpoints.up('sm')]: {
            pt: 10,
          },
        })}
      >
        <CursiveTypography variant='h2'>
          Nuestras Cartas
        </CursiveTypography>
        <Grid
          container
          maxWidth='sm'
          sx={{
            '& > .MuiGrid-item': {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              gap: 1,
            },
          }}
        >
          <Grid
            item
            xs={12}
            sm={6}
            p={1}
          >
            <Link href='/cartas/buque-insignia'>
              <StaticImage
                src='../../images/menus/buque-insignia.png'
                alt='Restaurant Buque Insignia'
                placeholder='none'
                quality={90}
              />
            </Link>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            p={1}
          >
            <Link href='/cartas/esmeralda'>
              <StaticImage
                src='../../images/menus/esmeralda.png'
                alt='Restaurant Esmeralda'
                placeholder='none'
                quality={90}
              />
            </Link>
          </Grid>
        </Grid>
      </Stack>
    </ParallaxLayer>
  </ParallaxGroup>
)

export default LandingMenus
