import * as React from 'react'

import { Button, Grid, Stack, Typography } from '@mui/material'
import { StaticImage } from 'gatsby-plugin-image'

import CursiveTypography from '../../components/cursive_typography'
import { ParallaxGroup, ParallaxLayer } from '../../components/parallax'

type RestaurantDisplayProps = {
  name: string
  locationDistrict: React.ReactNode
  locationAddress: React.ReactNode
  phoneNumber: React.ReactNode
  image: React.ReactNode
}

const RestaurantDisplay = ({
  name,
  locationDistrict,
  locationAddress,
  phoneNumber,
  image,
}: RestaurantDisplayProps) => (
  <React.Fragment>
    <Grid
      item
      xs={12}
      sm={6}
      p={1}
    >
      {image}
    </Grid>
    <Grid
      item
      xs={12}
      sm={6}
      py={3}
    >
      <CursiveTypography
        variant='h4'
        component='p'
      >
        {name}
      </CursiveTypography>
      <Typography
        textAlign='center'
        textTransform='uppercase'
      >
        {locationDistrict}
      </Typography>
      <Typography
        textAlign='center'
        textTransform='uppercase'
      >
        {locationAddress}
      </Typography>
      <Typography
        textAlign='center'
        gutterBottom
      >
        {phoneNumber}
      </Typography>
      <Button
        href='/reservar/'
        variant='contained'
        sx={{ mt: 1 }}
      >
        Reservar
      </Button>
    </Grid>
  </React.Fragment>
)

export const LandingRestaurants = () => (
  <ParallaxGroup
    id='restaurantes'
    sx={(theme) => ({
      height: '1200px !important',
      [theme.breakpoints.up('sm')]: {
        height: '810px !important',
      },
    })}
  >
    <ParallaxLayer bgcolor='background.default'>
      <Stack
        p={3}
        spacing={2}
        minHeight='100%'
        alignItems='center'
        justifyContent='center'
      >
        <StaticImage
          src='../../images/restaurants/divider-top.png'
          alt='Divisor de sección'
          placeholder='none'
          quality={90}
          style={{ maxWidth: 420 }}
        />
        <CursiveTypography variant='h2'>
          Nuestros Restaurantes
        </CursiveTypography>
        <Grid
          container
          maxWidth='sm'
          sx={{
            '& > .MuiGrid-item': {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              gap: 1,
            },
          }}
        >
          <RestaurantDisplay
            name='Buque Insignia'
            locationDistrict='STGO. CENTRO'
            locationAddress={(
              <React.Fragment>
                HUÉRFANOS&nbsp;2286 &mdash; AGUSTINAS&nbsp;2267
              </React.Fragment>
            )}
            phoneNumber='+56 9 6831 7281'
            image={(
              <StaticImage
                src='../../images/restaurants/buque-insignia.png'
                alt={'Restaurant Buque Insignia'}
                quality={90}
              />
            )}
          />
          <RestaurantDisplay
            name='Esmeralda'
            locationDistrict='VITACURA'
            locationAddress='AV. PADRE HURTADO NORTE 1480'
            phoneNumber='+56 9 6132 4837'
            image={(
              <StaticImage
                src='../../images/restaurants/esmeralda.jpeg'
                alt={'Restaurant Esmeralda'}
                quality={90}
              />
            )}
          />
        </Grid>
        <StaticImage
          src='../../images/restaurants/divider-bottom.png'
          alt='Divisor de sección'
          placeholder='none'
          quality={90}
          style={{ maxWidth: 420 }}
        />
      </Stack>
    </ParallaxLayer>
  </ParallaxGroup>
)

export default LandingRestaurants
