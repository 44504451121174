import * as React from 'react'

import { Grid, Link, Stack, Typography } from '@mui/material'
import { StaticImage } from 'gatsby-plugin-image'

import CursiveTypography from '../../components/cursive_typography'
import { ParallaxGroup, ParallaxLayer } from '../../components/parallax'

// eslint-disable-next-line max-len
const tripAdvisorUrl = 'https://www.tripadvisor.cl/Restaurant_Review-g294305-d1006325-Reviews-Ocean_Pacific_s-Santiago_Santiago_Metropolitan_Region.html'

type AwardDisplayProps = {
  href?: string
  children: React.ReactNode
}

const AwardDisplay = ({
  href,
  children,
}: AwardDisplayProps) => (
  <Grid
    item
    xs={6}
    sm={4}
    lg={2}
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}
  >
    {(typeof href === 'undefined') ? (
      children
    ) : (
      <Link
        target='_blank'
        rel='noopener noreferrer'
        href={href}
        sx={{
          position: 'relative',
          display: 'block',
        }}
      >
        {children}
      </Link>
    )}
  </Grid>
)

export const LandingAwards = () => (
  <ParallaxGroup
    id='galardones'
    sx={(theme) => ({
      zIndex: 2,
      minHeight: '700px',
      maxHeight: '700px',
      [theme.breakpoints.down('sm')]: {
        minHeight: '900px',
      },
    })}
  >
    <ParallaxLayer bgcolor='background.default'>
      <Stack
        p={3}
        minHeight='100%'
        alignItems='center'
        justifyContent='center'
      >
        <StaticImage
          src='../../images/awards/divider.png'
          alt='Divisor de sección'
          placeholder='none'
          quality={90}
          style={{ maxWidth: 420 }}
        />
        <CursiveTypography
          variant='h2'
          mt={2}
        >
          Galardones
        </CursiveTypography>
        <Grid
          container
          maxWidth='md'
          rowSpacing={2}
          py={3}
        >
          <AwardDisplay>
            <StaticImage
              src='../../images/awards/lianorg-2015.png'
              alt={'Lianorg 2015 Top Choice'}
              placeholder='none'
              quality={90}
              height={100}
            />
          </AwardDisplay>
          <AwardDisplay href={tripAdvisorUrl}>
            <StaticImage
              src='../../images/awards/hof-2015.jpeg'
              alt={'TripAdvisor Salón de la Fama 2015'}
              placeholder='none'
              quality={90}
              height={100}
            />
          </AwardDisplay>
          <AwardDisplay href={tripAdvisorUrl}>
            <StaticImage
              src='../../images/awards/excelencia-2015.jpeg'
              alt={'TripAdvisor Certificado de Excelencia 2015'}
              placeholder='none'
              quality={90}
              height={100}
            />
          </AwardDisplay>
          <AwardDisplay href={tripAdvisorUrl}>
            <StaticImage
              src='../../images/awards/excelencia-2017.jpeg'
              alt={'TripAdvisor Certificado de Excelencia 2017'}
              placeholder='none'
              quality={90}
              height={100}
            />
          </AwardDisplay>
          <AwardDisplay href={tripAdvisorUrl}>
            <StaticImage
              src='../../images/awards/excelencia-2018.png'
              alt={'TripAdvisor Certificado de Excelencia 2018'}
              placeholder='none'
              quality={90}
              height={100}
            />
          </AwardDisplay>
          <AwardDisplay href={tripAdvisorUrl}>
            <StaticImage
              src='../../images/awards/travelers-choice-2020.png'
              alt={'TripAdvisor Travelers Choice 2020'}
              placeholder='none'
              quality={90}
              height={100}
            />
          </AwardDisplay>
        </Grid>
        <Typography
          textTransform='uppercase'
          textAlign='justify'
          maxWidth='md'
          mb={2}
        >
          EL MEJOR RESTAURANTE EN SANTIAGO DE MARISCOS Y PESCADOS. SU DECORACIÓN
          ÚNICA LO CONVIERTE EN UN RESTAURANTE MUSEO, SIMULANDO ESTAR ABORDO DE
          UN BARCO, EN LA CABINA DEL CAPITÁN, DENTRO DE UN SUBMARINO O DE UN
          ENORME CACHALOTE. TANTO COMO UNA EXPERIENCIA VISUAL, SU GASTRONOMÍA ES
          DELICIOSA, ABUNDANTE Y PARA TODA LA TRIPULACIÓN. ES UN GRAN PANORAMA
          PARA ZARPAR EN CUALQUIER MOMENTO.
        </Typography>
        <StaticImage
          src='../../images/awards/divider.png'
          alt='Divisor de sección'
          placeholder='none'
          quality={90}
          style={{ maxWidth: 420 }}
        />
      </Stack>
    </ParallaxLayer>
  </ParallaxGroup>
)

export default LandingAwards
